@layer base {
  :root {
    --color-primary: 55 88 249 !important;
    .bg-primary {
      background: #3758F9 !important;
      background-color: #3758F9 !important;
    }
    .bg-primary-5 {
      background: #3758f90d !important;
      background-color: #3758f90d !important;
    }
    .text-primary {
      color: #3758F9 !important;
    }
    .border-primary {
      border-width: 1px;
      border-style: solid;
      border-color: #3758F9 !important;
      border-right-width: 4px !important;
    }
  }
}
* {
  margin: 0;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
