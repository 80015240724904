
.rdrCalendarWrapper {
    color: #111928 !important;
    border-radius: 8px;
    border: 1px solid var(--Stroke, #DFE4EA);
    background: var(--White, #FFF);

    /* Shadow 2 */
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.10);
    
    .rdrDateDisplayWrapper {
        background-color: #fff !important;
        .rdrDateDisplay {
            margin: 16px 10px;
            input {
                font-size: 16px;
                color: #111928 !important;
            }
        }
        .rdrDateDisplayItem {
            border-radius: 8px;
            box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);

        }
    }
    .rdrMonthAndYearWrapper {
        .rdrNextPrevButton {
            background: none;
        }
        .rdrMonthAndYearPickers {
            font-size: 16px;
            font-weight: 500;
        }
    }
    .rdrMonths {
        .rdrMonth {
            .rdrWeekDays {
                .rdrWeekDay {
                    font-size: 14px;
                    font-weight: 400;
                    color: #637381;
                }
            }
        }
    }
    .rdrDays {
        .rdrDay {
            line-height: 44px;
            height: 44px;
        }
        .rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge, .rdrEndEdge {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            left: 0px;
            right: 0px;
        }
        .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            left: 0px;
            right: 0px;
        }
        .rdrStartEdge {
            top: 2px;
            bottom: 2px;
            left: 0px;
            right: 0px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
        .rdrDayNumber {
            color: #111928;
            font-size: 16px;
            font-weight: 400;
            width: 44px;
            height: 35px;
        }

    }
}
.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
    content: '';
    border: 1px solid currentColor;
    border-radius: 4px !important;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0px;
    right: 0px;
    background: #3758F9 !important;
}
.rdrSelected{
    border-radius: 4px !important;
}
.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}
.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
     border-top-right-radius: 4px !important;
     border-bottom-right-radius: 4px !important;
}
.rdrDayStartPreview{
     border-top-left-radius: 4px !important;
     border-bottom-left-radius: 4px !important;
}
.rdrDayEndPreview{
     border-top-right-radius: 4px !important;
     border-bottom-right-radius: 4px !important;
}
.rdrDayToday .rdrDayNumber span:after {
    background: #3758F9 !important;
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    top: 2px !important;
    bottom: 2px !important;
}