.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}
.MuiSelect-select:focus {
  background-color: transparent;
}
.MuiInputBase-root, .MuiInputBase-input {
  /* color: #637381 !important; */
  font-size: 16px !important;
  font-weight: 400 !important;
}
.MuiInputBase-input:focus {
  box-shadow: none;
}
.MuiTableCell-body div {
  border: 0 !important;
}
.MuiTableCell-head {
  background-color: #3758F9 !important;
  color: #fff !important;
  padding: 18px !important;
  width: 100px !important;
}
.MuiTableCell-head:nth-last-child(1) {
  padding: 5px;
  border-radius: 0px 10px 0px 0px;
}
.MuiTableCell-head:nth-child(1) {
  border-radius: 10px 0px 0px 0px;
}
table table .MuiTableCell-head:nth-last-child(1) {
  padding: 5px;
  border-radius: 0px 0px 0px 0px;
}
table table .MuiTableCell-head:nth-child(1) {
  border-radius: 0px 0px 0px 0px;
}
table table .MuiTableCell-head {
  background-color: #3758f940 !important;
  color: #000 !important;
  padding: 0px 15px !important;
}

.MuiTableCell-body {
  background-color: #fff !important;
  color: #000 !important;
}

.makeStyles-menu-5 {
  background-color: #3758F9 !important;
}

section section div {
  /* border: 50px solid #fff !important; */
  box-shadow: none !important;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 6px !important;
  border: 1px solid #D1D5DB !important;
  font-size: 16px !important;
  color: #637381 !important;
}
.MuiButton-root {
  border-radius: 6px !important;
  height: 50px !important;
  font-size: 16px !important;
}
.MuiAutocomplete-tagSizeSmall {
  border-radius: 4px !important;
  background: #3056d314 !important;
}
@media (max-width: 600px) {
  .MuiAutocomplete-popper {
    width: 45% !important;
  }
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}